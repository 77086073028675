<template>
  <div>
    <b-container fluid>
        <b-row>
            <b-col>
                <div class="title"><h1>{{data.Title}}</h1></div>
            </b-col>
        </b-row>
        <b-row class="videoJsPlayer for_desktop" align="center">
            <b-col>
                <video-player class="video-player-box"
                    ref="videoPlayer"
                    :options="playerOptionsDesktop"
                    :playsinline="true"
                    customEventName="customstatechangedeventname">
                </video-player>
            </b-col>
        </b-row>
        <b-row class="videoJsPlayer for_tablet" align="center">
            <b-col>
                <video-player class="video-player-box"
                    ref="videoPlayer"
                    :options="playerOptionTablet"
                    :playsinline="true"
                    customEventName="customstatechangedeventname">
                </video-player>
            </b-col>
        </b-row>
        <b-row class="videoJsPlayer for_mobile" align="center">
            <b-col>
                <video-player class="video-player-box"
                    ref="videoPlayer"
                    :options="playerOptionsMobile"
                    :playsinline="true"
                    customEventName="customstatechangedeventname">
                </video-player>
            </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" class="for_desktop legal">
            <b-row>
              <b-col cols="12" class="title-legal" align="center">
                <!-- <img v-bind:src="'https://api.bisnisonlinebgs.com/uploads/images/icon/' + data.imgTitleLegal" width="45%"> -->
                <h1>{{data.titleLegal}}</h1>
              </b-col>
              <b-col cols="1" align="center"></b-col>
              <b-col cols="2" align="center">
                <img src="../../assets/images/cert/siup-mobile.png" width="80%">
                <p>SIUP KBLI47999</p>
              </b-col>
              <b-col cols="2" align="center">
                <img src="../../assets/images/cert/iso.png" width="80%">
                <p>ISO 9001:2015</p>
              </b-col>
              <b-col cols="2" align="center">
                <img src="../../assets/images/cert/keanggotaan-mobile.png" width="80%">
                <p>{{data.TitleApli}}</p>
              </b-col> 
              <b-col cols="2" align="center">
                <img src="../../assets/images/cert/apski-mobile.png" width="80%">
                <p>{{data.TitleApski}}</p>
              </b-col>
              <b-col cols="2" align="center">
                <img src="../../assets/images/cert/kominfo.png" width="80%">
                <p>{{data.TitleKominfo}}</p>
              </b-col>
              <b-col cols="1" align="center"></b-col>
            </b-row>
          </b-col>
          <b-col sm="12" class="for_tablet legal">
            <b-row>
              <b-col cols="12" class="title-legal" align="center">
                <!-- <img v-bind:src="'https://api.bisnisonlinebgs.com/uploads/images/icon/' + data.imgTitleLegal" width="45%"> -->
                <h1>{{data.titleLegal}}</h1>
              </b-col>
              <b-col cols="1" align="center"></b-col>
              <b-col cols="2" align="center">
                <img src="../../assets/images/cert/siup-mobile.png" width="80%">
                <p>SIUP KBLI47999</p>
              </b-col>
              <b-col cols="2" align="center">
                <img src="../../assets/images/cert/iso.png" width="80%">
                <p>ISO 9001:2015</p>
              </b-col>
              <b-col cols="2" align="center">
                <img src="../../assets/images/cert/keanggotaan-mobile.png" width="80%">
                <p>{{data.TitleApli}}</p>
              </b-col> 
              <b-col cols="2" align="center">
                <img src="../../assets/images/cert/apski-mobile.png" width="80%">
                <p>{{data.TitleApski}}</p>
              </b-col>
              <b-col cols="2" align="center">
                <img src="../../assets/images/cert/kominfo.png" width="80%">
                <p>{{data.TitleKominfo}}</p>
              </b-col>
              <b-col cols="1" align="center"></b-col>
            </b-row>
          </b-col>
          <b-col sm="12" class="for_mobile legal">
            <b-row>
              <b-col cols="12" class="title-legal" align="center">
                <!-- <img v-bind:src="'https://api.bisnisonlinebgs.com/uploads/images/icon/' + data.imgTitleLegal" width="90%"> -->
                <h1>{{data.titleLegal}}</h1>
              </b-col>
              <b-col cols="12" align="center">
                <img src="../../assets/images/cert/siup-mobile.png" width="80%">
                <p>SIUP KBLI47999</p>
              </b-col>
              <b-col cols="12" align="center">
                <img src="../../assets/images/cert/iso.png" width="80%">
                <p>ISO 9001:2015</p>
              </b-col>
               <b-col cols="12" align="center">
                <img src="../../assets/images/cert/keanggotaan-mobile.png" width="80%">
                <p>{{data.TitleApli}}</p>
              </b-col> 
              <b-col cols="12" align="center">
                <img src="../../assets/images/cert/apski-mobile.png" width="80%">
                <p>{{data.TitleApski}}</p>
              </b-col>
              <b-col cols="12" align="center">
                <img src="../../assets/images/cert/kominfo.png" width="80%">
                <p>{{data.TitleKominfo}}</p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
            <b-col sm="12" class="bannerContentNew">
                <b-row>
                  <b-col sm="6" class="imgMabelle"><img src="../../assets/images/icon/mibelle-group.png"></b-col>
                  <b-col sm="6">
                    <b-row>
                      <b-col sm="12"><h1 v-html="data.TitleMibelle"></h1></b-col>
                      <b-col sm="12" class="migos"><p> {{data.Desc1Mibelle}} <br>
                      <img src="../../assets/images/icon/migos.png">
                      <br> {{data.Desc2Mibelle}} </p>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
            </b-col>

            <div class="for_desktop bod">
              <b-row>
                <b-col cols="1"></b-col>
                <b-col cols="11" align="left"><h1>TOP MANAGEMENT</h1></b-col>
              </b-row>
              <b-row>
                <b-col cols="1"></b-col>
                <b-col cols="2" align="left"><img src="../../assets/images/larry.jpeg"></b-col>
                <b-col cols="8" align="left">
                  <h5><b>{{data.TopManagementName1}}</b></h5>
                  <h6><i>{{data.TopManagementFullName1}}</i></h6>
                  <p>{{data.TopManagementDesc1}}</p>
                </b-col>
                <b-col cols="1"></b-col>
              </b-row>
              <b-row>
                <b-col cols="1"></b-col>
                <b-col cols="2" align="left"><img src="../../assets/images/ferry.png"></b-col>
                <b-col cols="8" align="left">
                  <h5><b>{{data.TopManagementName2}}</b></h5>
                  <h6><i>{{data.TopManagementFullName2}}</i></h6>
                  <p>{{data.TopManagementDesc2}}</p>
                </b-col>
                <b-col cols="1"></b-col>
              </b-row>
              <b-row>
                <b-col cols="1"></b-col>
                <b-col cols="2" align="left"><img src="../../assets/images/duddy.jpg"></b-col>
                <b-col cols="8" align="left">
                  <h5><b>{{data.TopManagementName3}}</b></h5>
                  <h6><i>{{data.TopManagementFullName3}}</i></h6>
                  <p>{{data.TopManagementDesc3}}</p>
                </b-col>
                <b-col cols="1"></b-col>
              </b-row>
              <b-row>
                <b-col cols="1"></b-col>
                <b-col cols="2" align="left"><img src="../../assets/images/fred.jpeg"></b-col>
                <b-col cols="8" align="left">
                  <h5><b>{{data.TopManagementName4}}</b></h5>
                  <h6><i>{{data.TopManagementFullName4}}</i></h6>
                  <p>{{data.TopManagementDesc4}}</p>
                </b-col>
                <b-col cols="1"></b-col>
              </b-row>
              <b-row>
                <b-col cols="1"></b-col>
                <b-col cols="2" align="left"><img src="../../assets/images/richard.jpeg"></b-col>
                <b-col cols="8" align="left">
                  <h5><b>{{data.TopManagementName5}}</b></h5>
                  <h6><i>{{data.TopManagementFullName5}}</i></h6>
                  <p>{{data.TopManagementDesc5}}</p>
                </b-col>
                <b-col cols="1"></b-col>
              </b-row>
            </div>

            <div class="for_tablet bod">
              <b-row>
                <b-col cols="12" align="left"><h1>TOP MANAGEMENT</h1></b-col>
              </b-row>
              <b-row>
                <b-col cols="2" align="left"><img src="../../assets/images/larry.jpeg"></b-col>
                <b-col cols="10" align="left">
                  <h5><b>{{data.TopManagementName1}}</b></h5>
                  <h6><i>{{data.TopManagementFullName1}}</i></h6>
                  <p>{{data.TopManagementDesc1}}</p>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="2" align="left"><img src="../../assets/images/ferry.png"></b-col>
                <b-col cols="10" align="left">
                  <h5><b>{{data.TopManagementName2}}</b></h5>
                  <h6><i>{{data.TopManagementFullName2}}</i></h6>
                  <p>{{data.TopManagementDesc2}}</p>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="2" align="left"><img src="../../assets/images/duddy.jpg"></b-col>
                <b-col cols="10" align="left">
                  <h5><b>{{data.TopManagementName3}}</b></h5>
                  <h6><i>{{data.TopManagementFullName3}}</i></h6>
                  <p>{{data.TopManagementDesc3}}</p>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="2" align="left"><img src="../../assets/images/fred.jpeg"></b-col>
                <b-col cols="10" align="left">
                  <h5><b>{{data.TopManagementName4}}</b></h5>
                  <h6><i>{{data.TopManagementFullName4}}</i></h6>
                  <p>{{data.TopManagementDesc4}}</p>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="2" align="left"><img src="../../assets/images/richard.jpeg"></b-col>
                <b-col cols="10" align="left">
                  <h5><b>{{data.TopManagementName5}}</b></h5>
                  <h6><i>{{data.TopManagementFullName5}}</i></h6>
                  <p>{{data.TopManagementDesc5}}</p>
                </b-col>
              </b-row>
            </div>

            <div class="for_mobile bod">
              <b-row>
                <b-col cols="12" align="center"><h1>TOP MANAGEMENT</h1></b-col>
              </b-row>
              <b-row>
                <b-col cols="12" align="center"><img src="../../assets/images/larry.jpeg"></b-col>
               <b-row> 
                <b-col cols="12" align="left">
                  <h5><b>{{data.TopManagementName1}}</b></h5>
                  <h6><i>{{data.TopManagementFullName1}}</i></h6>
                  <p>{{data.TopManagementDesc1}}</p>
                </b-col>
                </b-row>
              </b-row>
              <hr>
              <b-row>
                <b-col cols="12" align="center"><img src="../../assets/images/ferry.png"></b-col>
                <b-col cols="12" align="left">
                  <h5><b>{{data.TopManagementName2}}</b></h5>
                  <h6><i>{{data.TopManagementFullName2}}</i></h6>
                  <p>{{data.TopManagementDesc2}}</p>
                </b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col cols="12" align="center"><img src="../../assets/images/duddy.jpg"></b-col>
                <b-col cols="12" align="left">
                  <h5><b>{{data.TopManagementName3}}</b></h5>
                  <h6><i>{{data.TopManagementFullName3}}</i></h6>
                  <p>{{data.TopManagementDesc3}}</p>
                </b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col cols="12" align="center"><img src="../../assets/images/fred.jpeg"></b-col>
                <b-col cols="12" align="left">
                  <h5><b>{{data.TopManagementName4}}</b></h5>
                  <h6><i>{{data.TopManagementFullName4}}</i></h6>
                  <p>{{data.TopManagementDesc4}}</p>
                </b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col cols="12" align="center"><img src="../../assets/images/richard.jpeg"></b-col>
                <b-col cols="12" align="left">
                  <h5><b>{{data.TopManagementName5}}</b></h5>
                  <h6><i>{{data.TopManagementFullName5}}</i></h6>
                  <p>{{data.TopManagementDesc5}}</p>
                </b-col>
              </b-row>
            </div>

            <b-col sm="12" class="bannerContent">
              <div class="for_desktop">
              <b-row>
                <b-col sm="12" class="penghargaan-title" align="center">
                  <!-- <img v-bind:src="'https://api.bisnisonlinebgs.com/uploads/images/icon/' + data.imgPengharhaan" width="35%" alt="Penghargaan 2018 Bisnisonline BGS" desc="Penghargaan 2018 Bisnisonline BGS"> -->
                  <h1>{{data.titlePenghargaan}}</h1>
                </b-col>
                <b-col sm="12">
                   <img src="../../assets/images/icon/penghargaan/penghargaan_lancape.png" width="95%" alt="Penghargaan 2018 Bisnisonline BGS" desc="Penghargaan 2018 Bisnisonline BGS">
                   <b-row style="width:95%">
                      <b-col cols="3" align="center">
                       <b style="margin-left:4rem">APLI Awards 2020</b>
                       <p style="margin-left:4rem">Enterpeneurship Of The Year</p>
                      </b-col>
                      <b-col cols="6" align="center">
                       <b style="margin-left:12rem">Best Nourshing Health Supplement</b>
                       <p style="margin-left:12rem">Asia Halal Brand 2019</p>
                      </b-col>
                      <b-col cols="3" align="right">
                       <b>The Best Quality Product & Customer Satisfaction Of The Year 2018</b>
                       <p>Best Of Indonesia 2018 Trusted Of Quality Award</p>
                      </b-col>
                   </b-row>
                </b-col>
                <!-- <b-col sm="4" align="left">
                   <img src="../../assets/images/icon/penghargaan-1-2.png" width="100%" alt="Penghargaan 2018 Bisnisonline BGS" desc="Penghargaan 2018 Bisnisonline BGS">
                </b-col> -->
              </b-row>
              </div>
              <div class="for_tablet">
              <b-row>
                <b-col sm="12" class="penghargaan-title" align="center">
                  <!-- <img v-bind:src="'https://api.bisnisonlinebgs.com/uploads/images/icon/' + data.imgPengharhaan" width="35%" alt="Penghargaan 2018 Bisnisonline BGS" desc="Penghargaan 2018 Bisnisonline BGS"> -->
                  <h1>{{data.titlePenghargaan}}</h1>
                </b-col>
                <b-col sm="12">
                   <img src="../../assets/images/icon/penghargaan/penghargaan_lancape.png" width="95%" alt="Penghargaan 2018 Bisnisonline BGS" desc="Penghargaan 2018 Bisnisonline BGS">
                   <b-row style="width:100%">
                      <b-col cols="3" align="center">
                       <b style="margin-left:2rem">APLI Awards 2020</b>
                       <p style="margin-left:2rem">Enterpeneurship Of The Year</p>
                      </b-col>
                      <b-col cols="5" align="center">
                       <b style="margin-left:2rem">Best Nourshing Health Supplement</b>
                       <p style="margin-left:2rem">Asia Halal Brand 2019</p>
                      </b-col>
                      <b-col cols="4" align="right">
                       <b style="margin-left:2rem">The Best Quality Product & Customer Satisfaction Of The Year 2018</b>
                       <p style="margin-left:2rem">Best Of Indonesia 2018 Trusted Of Quality Award</p>
                      </b-col>
                   </b-row>
                </b-col>
                <!-- <b-col sm="4" align="left">
                   <img src="../../assets/images/icon/penghargaan-1-2.png" width="100%" alt="Penghargaan 2018 Bisnisonline BGS" desc="Penghargaan 2018 Bisnisonline BGS">
                </b-col> -->
              </b-row>
              </div>
              <div class="for_mobile">
              <b-row>
                <b-col sm="12" class="penghargaan-title" align="center">
                  <!-- <img v-bind:src="'https://api.bisnisonlinebgs.com/uploads/images/icon/' + data.imgPengharhaan" width="35%" alt="Penghargaan 2018 Bisnisonline BGS" desc="Penghargaan 2018 Bisnisonline BGS"> -->
                  <h1>{{data.titlePenghargaan}}</h1>
                </b-col>
                <b-col sm="7" align="center">
                   <img src="../../assets/images/icon/penghargaan-1-3-mobile.png" width="90%" alt="Penghargaan 2018 Bisnisonline BGS" desc="Penghargaan 2018 Bisnisonline BGS">
                </b-col>
                <b-col sm="7" align="center">
                   <img src="../../assets/images/icon/penghargaan-1-1-mobile.png" width="90%" alt="Penghargaan 2018 Bisnisonline BGS" desc="Penghargaan 2018 Bisnisonline BGS">
                </b-col>
                <b-col sm="4" align="center">
                   <img src="../../assets/images/icon/penghargaan-1-2-mobile.png" width="100%" alt="Penghargaan 2018 Bisnisonline BGS" desc="Penghargaan 2018 Bisnisonline BGS">
                </b-col>
              </b-row>
              </div> 
            </b-col>
            <b-col sm="12" class="bannerContent">
              <div class="for_desktop">
              <b-row>
                <b-col sm="6" align="right">
                   <img src="../../assets/images/icon/penghargaan-2-1.png" width="80%" alt="Penghargaan 2018 Bisnisonline BGS" desc="Penghargaan 2018 Bisnisonline BGS">
                </b-col>
                <b-col sm="6" align="left">
                   <img src="../../assets/images/icon/penghargaan-2-2.png" width="80%" alt="Penghargaan 2018 Bisnisonline BGS" desc="Penghargaan 2018 Bisnisonline BGS">
                </b-col>
              </b-row>
              </div>
                <img class="for_tablet" src="https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/penghargaan2_lanscape.jpeg" width="100%" alt="Penghargaan 2018 Bisnisonline BGS" desc="Penghargaan 2018 Bisnisonline BGS">
              <div class="for_mobile">
              <b-row>
                <b-col sm="6" align="center">
                   <img src="../../assets/images/icon/penghargaan-2-1-mobile.png" width="80%" alt="Penghargaan 2018 Bisnisonline BGS" desc="Penghargaan 2018 Bisnisonline BGS">
                </b-col>
                <b-col sm="6" align="center">
                   <img src="../../assets/images/icon/penghargaan-2-2-mobile.png" width="80%" alt="Penghargaan 2018 Bisnisonline BGS" desc="Penghargaan 2018 Bisnisonline BGS">
                </b-col>
              </b-row>
              </div>
            </b-col>
        </b-row>

        <!-- <b-row>
            <b-col>
                <div class="title"><h1>EVENT TAHUNAN</h1></div>
            </b-col>
        </b-row>
        <div class="for_desktop">
        <b-row>
          <b-col cols="3" align="center"></b-col>
          <b-col cols="6" align="center">
            <b-carousel id="carousel-1" v-model="slide" :interval="3000" controls background="#ababab" style="text-shadow: 1px 1px 2px #333;">
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft1.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft2.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft3.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft4.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft5.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft6.jpeg"></b-carousel-slide>   
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft8.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft10.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft12.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft13.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft14.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft15.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft16.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft17.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft18.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft19.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft20.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft21.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft23.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft25.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft26.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft28.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft29.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft30.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft31.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft32.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft33.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft35.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft38.jpeg"></b-carousel-slide>
            </b-carousel>
          </b-col>
          <b-col cols="3" align="center"></b-col>
        </b-row>
        </div>
        <div class="for_tablet">
        <b-row>
          <b-col cols="2" align="center"></b-col>
          <b-col cols="8" align="center">
            <b-carousel id="carousel-1" v-model="slide" :interval="3000" controls background="#ababab" style="text-shadow: 1px 1px 2px #333;">
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft1.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft2.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft3.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft4.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft5.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft6.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft8.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft10.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft12.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft13.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft14.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft15.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft16.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft17.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft18.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft19.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft20.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft21.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft23.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft25.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft26.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft28.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft29.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft30.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft31.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft32.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft33.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft35.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft38.jpeg"></b-carousel-slide>
            </b-carousel>
          </b-col>
          <b-col cols="2" align="center"></b-col>
        </b-row>
        </div>
        <div class="for_mobile">
        <b-row>
          <b-col cols="1" align="center"></b-col>
          <b-col cols="12" align="center">
            <img src="../../assets/images/event/1.jpeg" width="100%" @click="clickEvent('all')">
            <transition name="fade">
              <div v-if="showEvent == 1" class="event">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft1.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft2.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft3.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft4.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft5.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft6.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft8.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft10.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft12.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft13.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft14.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft15.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft16.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft17.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft18.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft19.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft20.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft21.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft23.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft25.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft26.jpeg" width="100%"> 
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft28.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft29.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft30.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft31.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft32.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft33.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft34.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft35.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft36.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft38.jpeg" width="100%">
              </div>
            </transition>
          </b-col>
          <b-col cols="1" align="center"></b-col>
        </b-row>
        </div> -->

    </b-container>
  </div>
</template>

<script>
import axios from 'axios'
  export default {
    components:{

    },
    data() {
      return {
        bahasa:'id',
        data: {
          Title:null,
          TitleApli:null,
          TitleApski:null,
          TitleKominfo:null,

          TitleMibelle:null,
          Desc1Mibelle:null,
          Desc2Mibelle:null,

          TitleTopManagement:null,

          TopManagementName1:null,
          TopManagementFullName1:null,
          TopManagementDesc1:null,

          TopManagementName2:null,
          TopManagementFullName2:null,
          TopManagementDesc2:null,

          TopManagementName3:null,
          TopManagementFullName3:null,
          TopManagementDesc3:null,

          TopManagementName4:null,
          TopManagementFullName4:null,
          TopManagementDesc4:null,

          TopManagementName5:null,
          TopManagementFullName5:null,
          TopManagementDesc5:null,

          imgTitleLegal:"title-legal_id",

          imgPengharhaan:"title-penghargaan_id",

          titleLegal:null,

          titlePenghargaan:null,

        },
        showEvent:0,
        playerOptionsDesktop: {
          // videojs options
        //   muted: true,
          language: 'en',
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          width: '800px',
          height: '450px',
          sources: [{
            type: "video/mp4",
            src: "https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/Company-Profile.mp4"
          }],
          poster: "https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/Company-Profile.png",
        },
        playerOptionTablet: {
          // videojs options
        //   muted: true,
          language: 'en',
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          width: '700px',
          height: '420px',
          sources: [{
            type: "video/mp4",
            src: "https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/Company-Profile.mp4"
          }],
          poster: "https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/Company-Profile.png",
        },
        playerOptionsMobile: {
          // videojs options
        //   muted: true,
          language: 'en',
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          width: '290px',
          height: '170px',
          sources: [{
            type: "video/mp4",
            src: "https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/Company-Profile.mp4"
          }],
          poster: "https://api.bisnisonlinebgs.com/video/asset-bisnisonlinebgs/Company-Profile.png",
        },
        slide: 0,
        sliding: null
      }
    },
    mounted() {
      this.bahasa = localStorage.getItem('bahasa')
      this.data.imgTitleLegal = `title-legal_${this.bahasa}.png`
      this.data.imgPengharhaan = `title-penghargaan_${this.bahasa}.png`

      
      // console.log('this is current player instance object', this.player)
      axios.get(`../../lang_${this.bahasa}.json`).then(response => {
        // console.log(response.data.data[3].topManagement[0].topManagementName)

        this.data.Title = response.data.data[0].general[0].comproTitle
        this.data.TitleApli = response.data.data[1].legal[0].legalApli
        this.data.TitleApski = response.data.data[1].legal[0].legalApski
        this.data.TitleKominfo = response.data.data[1].legal[0].legalKominfo

        this.data.TitleMibelle = response.data.data[2].mibelle[0].mibelleTitle
        this.data.Desc1Mibelle = response.data.data[2].mibelle[0].mibelleDesc1
        this.data.Desc2Mibelle = response.data.data[2].mibelle[0].mibelleDesc2

        this.data.TopManagementName1 = response.data.data[3].topManagement[0].topManagementName
        this.data.TopManagementFullName1 = response.data.data[3].topManagement[0].topManagementFullname
        this.data.TopManagementDesc1 = response.data.data[3].topManagement[0].topManagementDesc

        this.data.TopManagementName2 = response.data.data[3].topManagement[1].topManagementName
        this.data.TopManagementFullName2 = response.data.data[3].topManagement[1].topManagementFullname
        this.data.TopManagementDesc2 = response.data.data[3].topManagement[1].topManagementDesc

        this.data.TopManagementName3 = response.data.data[3].topManagement[2].topManagementName
        this.data.TopManagementFullName3 = response.data.data[3].topManagement[2].topManagementFullname
        this.data.TopManagementDesc3 = response.data.data[3].topManagement[2].topManagementDesc

        this.data.TopManagementName4 = response.data.data[3].topManagement[3].topManagementName
        this.data.TopManagementFullName4 = response.data.data[3].topManagement[3].topManagementFullname
        this.data.TopManagementDesc4 = response.data.data[3].topManagement[3].topManagementDesc

        this.data.TopManagementName5 = response.data.data[3].topManagement[4].topManagementName
        this.data.TopManagementFullName5 = response.data.data[3].topManagement[4].topManagementFullname
        this.data.TopManagementDesc5 = response.data.data[3].topManagement[4].topManagementDesc

        this.data.titleLegal = response.data.data[1].legal[0].legalTitle
        
        this.data.titlePenghargaan = response.data.data[4].penghargaan[0].penghargaanTitle

        
      })
    },
    computed: {
      player() {
        return this.$refs.videoPlayer.player
      }
    },
    methods: {
      // onSlideStart(slide) {
      //   this.sliding = true
      // },
      // onSlideEnd(slide) {
      //   this.sliding = false
      // }
      clickEvent(){
            if(this.showEvent == 0){
                this.showEvent = 1
            } else {
                this.showEvent = 0
            }
            
      }
    }
  }
</script>

